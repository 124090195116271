import React from 'react'
import { EmbeddableContentPage } from '@src/templates/contentPage'
import Helmet from 'react-helmet'

export default function(props) {
	return (
		<>
			<Helmet
				meta={[
					{
						name: 'robots',
						content: 'noindex, nofollow',
					},
				]}
			/>
			<EmbeddableContentPage {...props} />
		</>
	)
}
