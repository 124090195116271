import './contentPage.scss'

import React from 'react'
import BEMHelper from 'react-bem-helper'
import { Container } from '@material-ui/core'
import Picture from '@src/components/Picture'
import { BrickResolver } from '@src/utils/BrickResolver'
import getThumbnailsFromBricks from '@src/utils/getThumbnailsFromBricks'
import Seo from '@src/components/Seo'

const bem = new BEMHelper('content-page') // TODO: fix missing scss resource

export const EmbeddableContentPage = ({ pageContext }) => {
	const { title } = pageContext

	const hideTitle =
		pageContext.data && pageContext.data.hideTitle ? true : false

	const images = getThumbnailsFromBricks(pageContext.data, 'header-image')

	const imageAlt =
		pageContext.data &&
		pageContext.data.editables.find(
			(editable) => editable.name === 'header-image',
		)
			? pageContext.data.editables.find(
					(editable) => editable.name === 'header-image',
			  ).title
			: null

	const text =
		pageContext.data &&
		pageContext.data.editables.find((editable) => editable.name === 'text')
			? pageContext.data.editables.find((editable) => editable.name === 'text')
					.value
			: null

	// TODO: Add snippet the same way as text? Look at Wysywyg brick perhaps?

	const header = hideTitle ? '' : title

	return (
		<div {...bem('')}>
			{images &&
				imageAlt &&
				images.map((image, index) => (
					<div key={index} {...bem('image')}>
						<Picture {...image} alt={imageAlt} />
					</div>
				))}
			<Container>
				{header && <h1 {...bem('title')}>{header}</h1>}
				{text && <p {...bem('text')}>{text}</p>}
			</Container>
			<BrickResolver pageContext={pageContext}></BrickResolver>
		</div>
	)
}

const ContentPage = ({ pageContext }) => {
	return (
		<>
			<Seo pageContextData={pageContext} />
			<EmbeddableContentPage pageContext={pageContext} />
		</>
	)
}

export default ContentPage
